import React from "react";
import { graphql } from "gatsby";

import { Seo } from "~/components/seo";
import { Main } from "~/layouts";
import { HeroTitle } from "~/components/hero";
import { Collections } from "~/components/collections";
import { Footer } from "~/components/footer";

const CollectionsPage = ({ data: { shopifyCollection } }) => {
  const { title, products } = shopifyCollection || {};
  return (
    <Main fullView>
      <Seo title={`${title} Cards`} />
      <HeroTitle {...{ title }} />
      <Collections {...{ products }} />
      <Footer />
    </Main>
  );
}

export const query = graphql`
  query($handle: String) {
    shopifyCollection(
      handle: { eq: $handle },
      products: { elemMatch: { status: { eq: "ACTIVE" } } }
    ) {
      id
      title
      description
      descriptionHtml
      slug: handle
      products {
        id
        shopifyId
        slug: handle
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        variants {
          id
          shopifyId
          price
          availableForSale
          displayName
          sku
          title
        }
        images {
          id
          altText
          originalSrc
          src
          width
          height
        }
        title
        description
        descriptionHtml
        productType
        tags
        status
        vendor
        vendorSlug
      }
    }
  }
`

export default CollectionsPage;
